/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.LegacyModal {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2000;
  overflow-y: auto;
}
.LegacyModal > .closeButton {
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
  transition: all 0.15s;
}
.LegacyModal > .closeButton:hover {
  transform: rotate(90deg) scale(1.1);
}
.LegacyModal > .wrapper {
  position: relative;
  width: 80%;
  min-height: 50%;
  margin: 6rem auto;
  margin-bottom: 10%;
  z-index: 200;
  background: #f9f6ef;
  border-radius: 0.8em;
  overflow: hidden;
  box-shadow: 0 0 10px #222;
}
.LegacyModal > .wrapper > header {
  padding: 1rem;
  height: 8rem;
}
.LegacyModal > .wrapper > header > h2 {
  font-size: 3rem;
  margin: 1.5rem 1rem;
  color: #fff;
  font-weight: 600;
}
.LegacyModal > .wrapper > header > .buttons {
  float: right;
}
.LegacyModal > .wrapper > header > .buttons > .ToolButton {
  margin: 0.3rem 1rem;
}
.LegacyModal > .wrapper > .contentWrapper {
  padding: 2rem;
}
.LegacyModal > .wrapper > .RoundButton.close {
  float: right;
  margin-top: -0.4em;
}
.LegacyModal > .wrapper > h2 {
  margin: 0;
  font-weight: 600;
  font-size: 1.8em;
  margin: 0.8em;
}
.LegacyModal > .wrapper > p {
  margin: 3rem;
  font-weight: 600;
  font-size: 2rem;
  color: #fff;
  line-height: 1.5;
}
.LegacyModal > .wrapper > p > b {
  color: #aae053;
}
.LegacyModal > .wrapper a {
  color: #ffdc4a;
  transition: all 0.15s;
}
.LegacyModal > .wrapper a:hover {
  color: #ffe67d;
}
.LegacyModal > .wrapper .TabbedSelector a {
  color: #095d84;
}
.LegacyModal > .wrapper .TabbedSelector a:hover {
  color: #063b54;
}
.LegacyModal > .wrapper .LegacyModal__thumbsUp {
  position: absolute;
  left: 2rem;
  bottom: -3rem;
  width: 38rem;
  height: 13rem;
}
.LegacyModal.large > .wrapper {
  width: 80%;
  min-height: 80%;
  top: 10%;
  padding: 0;
}
.LegacyModal.large > .wrapper > .RoundButton.close {
  margin-top: 0.4em;
}
.LegacyModal.purple {
  color: #fff;
}
.LegacyModal.purple > .wrapper {
  background: #745a9e;
}
.LegacyModal.purple > .wrapper > header {
  background: #614988;
}
.LegacyModal.dark > .wrapper {
  color: #fff;
  background: #2d272d;
}
.LegacyModal.blue > .wrapper {
  color: #fff;
  background: #4a82ff;
}
.LegacyModal .choiceTile {
  border-radius: 0.6em;
  border: 2px solid transparent;
  background: #614988;
  color: #fff;
  width: 13rem;
  height: 13rem;
  margin-left: 1.8rem;
  margin-bottom: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  font-size: 1.2em;
  padding-top: 6em;
  transition: all 0.15s ease 0s;
  transition: all 0.15s;
  position: relative;
  overflow: hidden;
}
.LegacyModal .choiceTile:hover {
  background: #5c477e;
}
.LegacyModal .choiceTile.selected {
  border-color: #fff;
}
.LegacyModal .choiceTile > .Image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
}
.LegacyModal .choiceTile > .text {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  width: 100%;
  padding: 0.8em;
}
