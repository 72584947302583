/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.greyBox {
  background: #fff;
  padding: 4rem;
  margin: 2rem auto;
  border-radius: 5rem;
  position: relative;
  min-height: 11rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.greyBox:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@media (max-width: 767px) {
  .greyBox {
    margin: 0.5rem auto;
    padding: 1rem;
    border-radius: 0;
  }
}
.greyBox.border {
  border-bottom: 0.7rem solid #e6e0d0;
  border-radius: 1rem;
}
.greyBox > p {
  text-align: left;
}
@media (min-width: 768px) {
  .greyBox {
    margin: 2rem -1.5rem auto;
  }
}
@media (min-width: 992px) {
  .greyBox {
    margin: 2rem -1.5rem auto;
  }
}
.greyBox .title,
.greyBox .subtitle {
  text-align: left;
  margin: 0 0 2rem;
  text-transform: none;
  color: #745a9e;
  font-weight: 600;
  font-size: 3.5rem;
}
.greyBox .subtitle {
  font-size: 2rem;
}
.greyBox .buttonWrapper {
  width: 100%;
  text-align: right;
  margin-left: 0.4rem;
  margin-top: 2rem;
}
.greyBox.greyBox.introPrompt {
  background: none;
  margin-bottom: -2.5rem;
  background-color: transparent;
}
.purpleBox {
  background: #534468;
  padding: 2rem;
  border-radius: 0.5rem;
}
.purpleBox:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
