/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.RoundButton {
  display: inline-block;
  border-radius: 5rem;
  color: #fff;
  padding: 2.4rem 4.4rem;
  border: none;
  margin: 0.5rem;
  transition: all 0.15s ease 0s;
  transition: all 0.15s;
  font-family: 'Zona Pro', 'Verdana';
  font-size: 1.8rem;
  font-weight: bold;
}
.RoundButton:focus,
.RoundButton:hover {
  outline: none;
  color: #fff;
}
.RoundButton:focus {
  box-shadow: 0 0 0 0.1em #fff;
}
.RoundButton:hover {
  cursor: pointer;
}
.RoundButton.large {
  font-size: 2.2rem;
  padding: 2.6rem 4.8rem;
}
@media (max-width: 991px) {
  .RoundButton.large {
    font-size: 1.1rem;
    padding: 1.6rem 3.2rem;
  }
}
.RoundButton.medium {
  font-size: 1.8rem;
  padding: 2.4rem 4.4rem;
}
@media (max-width: 991px) {
  .RoundButton.medium {
    font-size: 1.4rem;
    padding: 2rem 4rem;
  }
}
.RoundButton.small {
  font-size: 1.4rem;
  padding: 1.4rem 3.2rem;
}
@media (max-width: 991px) {
  .RoundButton.small {
    font-size: 1.1rem;
    padding: 1.6rem 3.2rem;
  }
}
.RoundButton.tiny {
  padding: 0.8rem 2.5rem;
  font-size: 1.2rem;
}
.RoundButton.blue {
  background: #4a82ff;
}
.RoundButton.blue:hover {
  background: #3170ff;
}
.RoundButton.purple {
  background: #745a9e;
}
.RoundButton.purple:hover {
  background: #44355d;
}
.RoundButton.darkPurple {
  background: #614988;
}
.RoundButton.darkPurple:hover {
  background: #322546;
}
.RoundButton.red {
  background: #ff486b;
}
.RoundButton.red:hover {
  background: #ff2e56;
}
.RoundButton.darkRed {
  background: #f3365a;
}
.RoundButton.darkRed:hover {
  background: #f21e46;
}
.RoundButton.grey {
  background: #e4d7b7;
  color: #534468;
}
.RoundButton.grey:hover {
  background: #ddcca5;
}
.RoundButton.green {
  background: #86c323;
}
.RoundButton.green:hover {
  background: #77ae1f;
}
.RoundButton.yellow {
  background: #ffdc4a;
  color: #534468;
}
.RoundButton.yellow:hover {
  background: #e3b700;
}
.RoundButton.pending {
  cursor: default;
}
.RoundButton.disabled:not(.pending):not(.error) {
  background-color: #ccc;
  color: #fff;
  cursor: default;
}
.RoundButton .Image {
  float: left;
  margin-right: 1.5rem;
  margin-top: -0.3rem;
}
.RoundButton .Image img {
  height: 2.8rem;
}
.RoundButton .Image .buttonLoadingWheel {
  width: 2rem;
  height: 2rem;
  margin-left: 1.2rem;
}
