/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#container > .frame > .pageContainer {
  overflow: hidden;
  background-color: #f9f6ef;
  font-family: 'Zona Pro', 'Verdana';
  /* Basic */
  padding-top: 9rem;
  padding-bottom: 10rem;
}
#container > .frame > .pageContainer .maxWidthContentWrapper {
  max-width: 140rem;
  margin: 0 auto;
}
#container > .frame > .pageContainer li > a {
  vertical-align: top;
}
@media (max-width: 1035px) {
  #container > .frame > .pageContainer {
    padding-top: 6rem;
  }
}
