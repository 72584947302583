/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ProgressBar {
  background: #e6e0d0;
  border-radius: 0.6em;
  font-size: 0.85em;
  font-weight: 600;
  position: relative;
  height: 1.2em;
  overflow: hidden;
}
.ProgressBar.purple > .bar {
  background: #745a9e;
}
.ProgressBar.blue > .bar {
  background: #4a82ff;
}
.ProgressBar > .bar {
  transition: all 2s;
  border-radius: 0.6em;
  height: 100%;
  background: #9cd541;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.ProgressBar__image {
  margin-top: -0.2em;
}
.ProgressBar__label {
  float: right;
  font-weight: bold;
  color: #534468;
  margin-left: 0.5em;
}
.ProgressBar__projectTile .ProgressBar {
  height: 1em;
  margin-top: 1em;
  margin-right: 3em;
}
.ProgressBar__Label---uppercase {
  text-transform: uppercase;
}
