/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#container > .frame > .reactFooterWrapper > .footer {
  position: absolute;
  bottom: 0;
  background-color: #614988;
  height: 11rem;
  width: 100%;
  line-height: 1.5;
  font-family: 'Zona Pro', 'Verdana';
}
@media (max-width: 991px) {
  #container > .frame > .reactFooterWrapper > .footer {
    height: 23.5rem;
  }
}
#container > .frame > .reactFooterWrapper > .footer.promo {
  height: 51rem;
}
@media (max-width: 991px) {
  #container > .frame > .reactFooterWrapper > .footer.promo {
    height: 63.5rem;
  }
}
#container > .frame > .reactFooterWrapper > .footer .footerWrapper {
  text-align: center;
  width: 100%;
  max-width: 120rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto;
}
#container > .frame > .reactFooterWrapper > .footer .footerWrapper .logo {
  text-align: left;
  font-weight: bold;
  position: relative;
  top: 3.6rem;
}
#container > .frame > .reactFooterWrapper > .footer .copyright {
  color: #9983bb;
  position: relative;
  top: 1.65rem;
  text-align: right;
  font-size: 1.3rem;
  padding-right: 3rem;
}
@media (max-width: 991px) {
  #container > .frame > .reactFooterWrapper > .footer .copyright {
    width: 100%;
    text-align: left;
    top: 14rem;
  }
}
#container > .frame > .reactFooterWrapper > .footer .links {
  padding-right: 19rem;
  position: relative;
  top: -1.8rem;
  width: 100%;
  text-align: right;
}
@media (max-width: 991px) {
  #container > .frame > .reactFooterWrapper > .footer .links {
    width: 100%;
    text-align: left;
    padding-top: 4rem;
    padding-right: 0;
  }
}
#container > .frame > .reactFooterWrapper > .footer .links a {
  color: #ffffff;
  font-weight: 600;
  color: #9983bb;
  font-size: 1.4rem;
  margin-right: 1rem;
  margin-left: 1rem;
}
#container > .frame > .reactFooterWrapper > .footer .links li {
  display: inline-block;
}
@media (max-width: 991px) {
  #container > .frame > .reactFooterWrapper > .footer .links li {
    width: 50%;
  }
}
#container > .frame > .reactFooterWrapper > .footer ul {
  list-style: none;
}
@media (max-width: 991px) {
  #container > .frame > .reactFooterWrapper > .footer ul {
    padding: 0;
  }
}
