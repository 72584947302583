/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.authProviders {
  text-align: center;
}
.authProviders > div {
  display: inline-block;
  margin: 20px;
}
.authProviders > .facebookSignOn {
  position: relative;
  top: -20px;
  cursor: pointer;
}
.authProviders > .ckStubSignOn {
  font-size: 12px;
}
.authProviders .break {
  display: flex;
  width: 100%;
  width: 80%;
  margin: 14px auto;
}
.authProviders .break > .line:before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #e6e0d0;
}
.authProviders .break > .left {
  margin-right: 30px;
  flex: 1;
  position: relative;
}
.authProviders .break > .right {
  flex: 1;
  position: relative;
  margin-left: 30px;
}
.authProviders .break > .text {
  font-size: 14px;
  font-weight: bold;
}
