@font-face {
	font-family: 'Inconsolata';
	src: url('/fonts/Inconsolata.woff') format('woff'),
		url('/fonts/Inconsolata.ttf') format('truetype'),
		url('/fonts/Inconsolata.oft') format('opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Inconsolata';
	src: url('/fonts/Inconsolata-Bold.woff2') format('woff2'),
		url('/fonts/Inconsolata-Bold.woff') format('woff'),
		url('/fonts/Inconsolata-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-SemiBold.woff2') format('woff2'),
		url('/fonts/ZonaPro-SemiBold.woff') format('woff'),
		url('/fonts/ZonaPro-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-Regular.woff2') format('woff2'),
		url('/fonts/ZonaPro-Regular.woff') format('woff'),
		url('/fonts/ZonaPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-ExtraBoldItalic.woff2') format('woff2'),
		url('/fonts/ZonaPro-ExtraBoldItalic.woff') format('woff'),
		url('/fonts/ZonaPro-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Zona Pro Regular';
	src: url('/fonts/ZonaPro-RegularItalic.woff2') format('woff2'),
		url('/fonts/ZonaPro-RegularItalic.woff') format('woff'),
		url('/fonts/ZonaPro-RegularItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-BoldItalic.woff2') format('woff2'),
		url('/fonts/ZonaPro-BoldItalic.woff') format('woff'),
		url('/fonts/ZonaPro-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-Light.woff2') format('woff2'),
		url('/fonts/ZonaPro-Light.woff') format('woff'),
		url('/fonts/ZonaPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-Bold.woff2') format('woff2'),
		url('/fonts/ZonaPro-Bold.woff') format('woff'),
		url('/fonts/ZonaPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-Black.woff2') format('woff2'),
		url('/fonts/ZonaPro-Black.woff') format('woff'),
		url('/fonts/ZonaPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Zona Pro Hairline';
	src: url('/fonts/ZonaPro-HairlineItalic.woff2') format('woff2'),
		url('/fonts/ZonaPro-HairlineItalic.woff') format('woff'),
		url('/fonts/ZonaPro-HairlineItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-BlackItalic.woff2') format('woff2'),
		url('/fonts/ZonaPro-BlackItalic.woff') format('woff'),
		url('/fonts/ZonaPro-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-Thin.woff2') format('woff2'),
		url('/fonts/ZonaPro-Thin.woff') format('woff'),
		url('/fonts/ZonaPro-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Zona Pro';
	src: url('/fonts/ZonaPro-LightItalic.woff2') format('woff2'),
		url('/fonts/ZonaPro-LightItalic.woff') format('woff'),
		url('/fonts/ZonaPro-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Cabin';
	src: url('/fonts/Cabin-Regular.woff2') format('woff2'),
		url('/fonts/Cabin-Regular.woff') format('woff'),
		url('/fonts/Cabin-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Cabin';
	src: url('/fonts/Cabin-Bold.woff2') format('woff2'), url('/fonts/Cabin-Bold.woff') format('woff'),
		url('/fonts/Cabin-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Source Code Pro';
	src: url('/fonts/SourceCodePro-SemiBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Source Code Pro';
	src: url('/fonts/SourceCodePro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/fonts/Rubik-SemiBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/fonts/Rubik-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('/fonts/Rubik-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}



@font-face {
	font-family: 'DMSans';
	src: url('/fonts/DMSans-SemiBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'DMSans';
	src: url('/fonts/DMSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DMSans';
	src: url('/fonts/DMSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}