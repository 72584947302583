/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.LegacyModal.MessageModal > .wrapper {
  position: absolute;
  top: 0;
  margin: 12rem auto;
  height: 50rem;
  width: 60%;
  left: 20%;
  background: #f9f6ef;
  text-align: center;
}
@media (max-width: 1300px) {
  .LegacyModal.MessageModal > .wrapper {
    width: 80%;
    left: 10%;
  }
}
@media (max-width: 767px) {
  .LegacyModal.MessageModal > .wrapper {
    width: 90%;
    left: 5%;
    margin: 8rem auto;
  }
}
.LegacyModal.MessageModal > .wrapper > .title {
  font-size: 3.2rem;
  margin-top: 3rem;
  color: #745a9e;
  font-weight: 600;
  padding: 3rem;
  position: relative;
  z-index: 10;
}
@media (max-width: 767px) {
  .LegacyModal.MessageModal > .wrapper > .title {
    padding: 1.5rem;
  }
}
.LegacyModal.MessageModal > .wrapper > .message {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: 600;
  padding: 3rem 10rem;
  position: relative;
  z-index: 10;
}
@media (max-width: 767px) {
  .LegacyModal.MessageModal > .wrapper > .message {
    font-size: 1.4rem;
    padding: 1.5rem;
  }
}
.LegacyModal.MessageModal > .wrapper > .cloudLeft {
  position: absolute;
  top: 15rem;
  left: -12rem;
}
.LegacyModal.MessageModal > .wrapper > .pipeRight {
  position: absolute;
  top: 2rem;
  right: -1.1rem;
}
@media (max-width: 767px) {
  .LegacyModal.MessageModal > .wrapper > .pipeRight {
    display: none;
  }
}
.LegacyModal.MessageModal > .wrapper > .cloudMiddle {
  position: absolute;
  top: 34rem;
  left: 6rem;
}
.LegacyModal.MessageModal > .wrapper .buttonWrapper {
  margin: 0 auto;
  position: relative;
  z-index: 10;
}
.LegacyModal.MessageModal > .wrapper .buttonWrapper .RoundButton {
  display: block;
  margin: 2rem auto 0;
}
.LegacyModal.MessageModal > .wrapper img {
  padding: 1rem;
  margin: 1rem auto 0;
}
