@searchPanelWidth: 30em;
@containerWidth: 1400px;

@import './Palette.less';

.focusable(@colour: #fff) {
	&:focus,
	&:hover {
		outline: none;
		color: #fff;
	}

	&:focus {
		box-shadow: 0 0 0 0.1em @colour;
	}
}

.transition() {
	transition: all 0.15s;
}

.scrollbars(@colour: @darkPurple) {
	overflow: scroll;

	> .horizontalTrack {
		bottom: 0.4em;
		height: 0.4em !important;
		width: 100%;

		> .horizontalThumb {
			border-radius: 1em;
			background: @colour;
		}
	}

	> .verticalTrack {
		right: 0.4em;
		width: 0.4em !important;
		height: 100%;

		> .verticalThumb {
			border-radius: 1em;
			background: @colour;
		}
	}

	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: @colour;
		box-shadow: none;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}

	-ms-overflow-style: -ms-autohiding-scrollbar;
}
