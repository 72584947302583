/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#container > .frame > .pageContainer {
  /* With just promo footer */
  /* With promo header and footer */
}
#container > .frame > .pageContainer > .content > div {
  min-height: calc(100vh - 9rem - 11rem);
}
#container > .frame > .pageContainer.promoFooter {
  padding-bottom: 51rem;
}
#container > .frame > .pageContainer.promoFooter > .content > div {
  min-height: calc(100vh - 9rem - 11rem - 40rem);
}
#container > .frame > .pageContainer.promoHeader {
  padding-top: 18rem;
}
#container > .frame > .pageContainer.promoHeader.promoFooter > .content > div {
  min-height: calc(100vh - 9rem - 9rem - 11rem - 40rem);
}
@media (max-width: 1035px) {
  #container > .frame > .pageContainer {
    /* Basic */
    padding-bottom: 23.5rem;
    /* With just promo footer */
    /* With promo header and footer */
  }
  #container > .frame > .pageContainer > .content > div {
    min-height: calc(100vh - 6rem - 23.5rem);
  }
  #container > .frame > .pageContainer.promoFooter {
    padding-bottom: 63.5rem;
  }
  #container > .frame > .pageContainer.promoFooter > .content > div {
    min-height: calc(100vh - 6rem - 23.5rem - 40rem);
  }
  #container > .frame > .pageContainer.promoHeader {
    padding-top: 15rem;
  }
  #container > .frame > .pageContainer.promoHeader.promoFooter > .content > div {
    min-height: calc(100vh - 6rem - 9rem - 23.5rem - 40rem);
  }
}
@media (max-width: 991px) {
  .code_editor_frame .wrapper > a > .Image > .logo {
    display: none;
  }
}
#container > .frame > .reactMenuWrapper > .menu {
  width: 100%;
  margin: 0;
  overflow: visible;
  background-color: #fff;
  transition: 0.3s all;
  position: fixed;
  top: 0;
  z-index: 400;
  height: 9rem;
  line-height: 1.5;
}
@media (max-width: 1035px) {
  #container > .frame > .reactMenuWrapper > .menu {
    height: 6rem;
  }
}
#container > .frame > .reactMenuWrapper > .menu .avatar {
  float: right;
  font-size: 1.5rem;
}
@media (max-width: 1035px) {
  #container > .frame > .reactMenuWrapper > .menu .avatar {
    margin: 0 auto;
    max-width: 39rem;
    padding-left: 1.5rem;
    float: left;
  }
}
#container > .frame > .reactMenuWrapper > .menu .avatar > .Image {
  width: 7rem;
  height: 7rem;
  float: right;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 1035px) {
  #container > .frame > .reactMenuWrapper > .menu .avatar > .Image {
    float: left;
  }
}
#container > .frame > .reactMenuWrapper > .menu .avatar > .details {
  margin: 0 8.5rem 0 0;
  min-width: 20rem;
  text-align: right;
}
@media (max-width: 1035px) {
  #container > .frame > .reactMenuWrapper > .menu .avatar > .details {
    text-align: left;
    padding-left: 1rem;
    margin: 0;
    float: left;
  }
}
#container > .frame > .reactMenuWrapper > .menu .avatar > .details > .name {
  color: #534468;
  font-weight: bold;
  display: block;
}
#container > .frame > .reactMenuWrapper > .menu .avatar > .details > .level {
  font-weight: 600;
  color: #3c7501;
}
#container > .frame > .reactMenuWrapper > .menu .avatar > .details > .level > .ProgressBar {
  height: 1.2rem;
  margin: 1rem 0;
  background: #f9f6ef;
}
#container > .frame > .reactMenuWrapper > .menu .avatar > .details > .level > .text {
  margin: 0.5rem 0;
}
#container > .frame > .reactMenuWrapper > .menu .avatar > .details > .level > .text > .Image {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
  position: relative;
  top: -0.2rem;
}
#container > .frame > .reactMenuWrapper > .menu .avatar > .details > .level > .xp {
  font-size: 1.2rem;
  float: left;
  margin-top: 0.5rem;
}
#container > .frame > .reactMenuWrapper > .menu.promo {
  height: 18rem;
}
@media (max-width: 1035px) {
  #container > .frame > .reactMenuWrapper > .menu.promo {
    height: 15rem;
  }
  #container > .frame > .reactMenuWrapper > .menu.promo .bm-burger-button {
    top: 14rem;
  }
}
#container > .frame > .reactMenuWrapper > .menu > .promo {
  height: 9rem;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.blackfriday {
  background-color: #221f25;
  color: #fff;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.blackfriday .title {
  font-size: 2.6rem;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.holidaysale {
  background-color: #ff486b;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.holidaysale .title {
  font-size: 2.6rem;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.easter {
  background-color: #745a9e;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.easter > .backgroundLeft {
  left: -8rem;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.easter .title {
  font-size: 2.6rem;
}
#container > .frame > .reactMenuWrapper > .menu > .promo > .backgroundLeft {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
}
#container > .frame > .reactMenuWrapper > .menu > .promo > .backgroundRight {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto 100%;
}
@media (max-width: 991px) {
  #container > .frame > .reactMenuWrapper > .menu > .promo > .backgroundRight {
    display: none;
  }
}
#container > .frame > .reactMenuWrapper > .menu > .promo .PromoCountdown .values {
  font-size: 1.8rem;
}
#container > .frame > .reactMenuWrapper > .menu > .promo .PromoCountdown .legend {
  font-size: 1.1rem;
  margin-top: -0.5rem;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.summer {
  background-color: #b6e7ff;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.summer .title {
  color: #007dba;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.homeschooling {
  background-color: #4a82ff;
  color: #fff;
}
#container > .frame > .reactMenuWrapper > .menu > .promo.homeschooling .title {
  color: #fff;
}
#container > .frame > .reactMenuWrapper > .menu > .promo > .title {
  color: #fff;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
  z-index: 2;
  transform-style: preserve-3d;
  transition: 0.6s;
  width: 55rem;
  height: 9rem;
  max-width: 90%;
}
@media (max-width: 767px) {
  #container > .frame > .reactMenuWrapper > .menu > .promo > .title {
    font-size: 1.6rem;
  }
}
#container > .frame > .reactMenuWrapper > .menu > .promo > .title.flipped {
  transform: rotateX(180deg);
}
#container > .frame > .reactMenuWrapper > .menu > .promo > .title > div {
  backface-visibility: hidden;
  position: absolute;
  transform: rotateX(0deg);
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#container > .frame > .reactMenuWrapper > .menu > .promo > .title > .back {
  transform: rotateX(180deg);
}
#container > .frame > .reactMenuWrapper > .menu > .promo > .evenWrapper {
  flex: 0 0 22rem;
  position: relative;
  z-index: 2;
}
@media (max-width: 991px) {
  #container > .frame > .reactMenuWrapper > .menu > .promo > .evenWrapper {
    display: none;
  }
}
#container > .frame > .reactMenuWrapper > .menu > .wrapper {
  margin: 0 auto;
  position: relative;
  max-width: 140rem;
  padding-right: 8.5rem;
  padding-top: 1rem;
  height: 9rem;
}
#container > .frame > .reactMenuWrapper > .menu > .wrapper .logo {
  width: 7rem;
  height: 3.8rem;
  position: absolute;
  top: 2.5rem;
  left: 50%;
  margin-left: -3.5rem;
}
@media (max-width: 1035px) {
  #container > .frame > .reactMenuWrapper > .menu > .wrapper .logo {
    top: 1.2rem;
    width: 6rem;
    margin-left: -3rem;
  }
}
#container > .frame > .reactMenuWrapper > .menu > .wrapper .coolmath-logo {
  position: absolute;
  top: 2.5rem;
  left: 50%;
  margin-left: -6.5rem;
  width: 13rem;
  height: 3.8rem;
}
#container > .frame > .reactMenuWrapper > .menu > .wrapper > .userTool {
  float: right;
}
#container > .frame > .reactMenuWrapper > .menu > .wrapper > .startCoding > a {
  cursor: pointer;
  font-weight: bold;
}
#container > .frame > .reactMenuWrapper > .menu .item {
  display: inline;
}
#container > .frame > .reactMenuWrapper > .menu .item > a {
  color: #534468;
  font-family: 'Zona Pro', 'Verdana';
  font-weight: 600;
  font-size: 1.4rem;
  background-color: transparent;
  padding: 2.4rem 3rem;
  transition: 0.3s all;
  max-width: 30rem;
  overflow: hidden;
}
#container > .frame > .reactMenuWrapper > .menu .item > a:hover {
  text-decoration: none;
  color: #aae053;
}
#container > .frame > .reactMenuWrapper > .menu .item:first-child {
  margin-left: 0;
}
#container > .frame > .reactMenuWrapper > .menu .roundButtonWrapper {
  float: right;
  margin-top: -1.8rem;
}
@media (max-width: 991px) {
  #container > .frame > .reactMenuWrapper > .menu .roundButtonWrapper {
    display: none;
  }
}
#container > .frame > .reactMenuWrapper > .menu .roundButtonWrapper .startCoding {
  margin-top: 0;
  margin-left: -3.8rem;
}
#container > .frame > .reactMenuWrapper > .menu .bm-menu-wrap {
  top: 0;
  width: 100% !important;
  text-align: center;
  z-index: 500;
}
#container > .frame > .reactMenuWrapper > .menu .bm-burger-button {
  display: none;
}
#container > .frame > .reactMenuWrapper > .menu .bm-burger-bars {
  background: #534468;
}
#container > .frame > .reactMenuWrapper > .menu .bm-cross {
  background: #fff;
}
#container > .frame > .reactMenuWrapper > .menu .bm-cross-button {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 50%;
  top: 15px;
  transform: scale(5);
  margin-top: 8rem;
  margin-left: -2rem;
}
#container > .frame > .reactMenuWrapper > .menu .bm-menu {
  background: #fff;
  padding: 2.5rem 1.5rem 0;
  font-size: 1.15rem;
  background: url('/graphics/generic/bm-background.svg') center center / cover no-repeat #745a9e;
}
#container > .frame > .reactMenuWrapper > .menu .bm-item-list {
  margin-top: 20rem;
}
#container > .frame > .reactMenuWrapper > .menu .bm-item-list .avatar {
  display: none !important;
}
#container > .frame > .reactMenuWrapper > .menu .bm-item-list a {
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 3em;
  padding: 1.8rem 0;
}
@media (max-width: 1035px) {
  #container > .frame > .reactMenuWrapper > .menu .logo {
    position: absolute;
    left: 50%;
    margin-left: -35px;
    top: 3px;
  }
  #container > .frame > .reactMenuWrapper > .menu .bm-burger-button {
    display: inline-block;
    position: absolute;
    top: 2.25rem;
    right: 1.5rem;
    z-index: 10;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.7);
    width: 40px;
    height: 40px;
    padding-top: 6px;
    padding-left: 11px;
  }
  #container > .frame > .reactMenuWrapper > .menu > .wrapper > .item {
    display: none;
  }
}
.Menu__mobile-menu-wrapper {
  display: none;
}
@media (max-width: 1035px) {
  .Menu__mobile-menu-wrapper {
    display: block;
  }
}
.Menu__mobile-menu {
  height: 100%;
  width: 100%;
  right: 100%;
  top: 0;
  overflow: hidden;
  position: fixed;
  background-color: #534468;
  transition: 0.5s ease-in-out all;
  background: url(/graphics/generic/bm-background.svg) center center / cover no-repeat #745a9e;
  display: none;
  z-index: 3;
}
@media (max-width: 1035px) {
  .Menu__mobile-menu {
    display: block;
  }
}
.Menu__mobile-menu--open {
  right: 0;
}
.Menu__mobile-menu__list {
  list-style: none;
  padding: 0;
  padding-top: 3rem;
  padding-left: 3rem;
  margin: auto;
  font-size: 3.5rem;
  font-weight: 600;
  position: absolute;
}
.Menu__mobile-menu__item {
  color: white;
  word-break: break-all;
  padding-bottom: 0.5rem;
  cursor: pointer;
}
.Menu__mobile-menu__href:hover {
  text-decoration: underline;
}
.Menu__mobile-menu__href,
.Menu__mobile-menu__href:active,
.Menu__mobile-menu__href:hover {
  color: white;
}
.Menu__mobile-menu__close {
  position: absolute;
  right: 0;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  padding: 1.5rem 2.5rem;
  padding: 0 2.5rem;
  z-index: 20000;
  top: 1.3rem;
}
.Menu__mobile-menu__hamburger {
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.Menu__mobile-menu__hamburger-line {
  width: 25px;
  height: 4px;
  margin: 5px auto;
  background-color: #745a9e;
  display: block;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 1rem;
}
.Menu__mobile-menu__hamburger--active:before {
  border: 5px solid #f9f6ef;
}
.Menu__mobile-menu__hamburger-line--active:nth-child(2) {
  background-color: #f9f6ef;
  opacity: 0;
}
.Menu__mobile-menu__hamburger-line--active:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
  background-color: #f9f6ef;
}
.Menu__mobile-menu__hamburger-line--active:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
  background-color: #f9f6ef;
}
