/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.GenericPromoHeader {
  background: #fff;
  height: 5rem;
  cursor: pointer;
  font-size: 1.5rem;
  position: relative;
  font-weight: 600;
}
.GenericPromoHeader--hide {
  display: none;
}
.GenericPromoHeader__contentWrapper {
  height: 100%;
}
@media (max-width: 767px) {
  .GenericPromoHeader__contentWrapper {
    display: initial;
  }
}
.GenericPromoHeader__contentWrapper__mobileImage {
  display: none;
  position: absolute;
  left: 0;
  height: 5rem;
}
@media (max-width: 767px) {
  .GenericPromoHeader__contentWrapper__mobileImage {
    display: initial;
  }
}
.GenericPromoHeader__contentWrapper__mobileImage > .Image {
  height: 100%;
}
.GenericPromoHeader__contentWrapper__mobileImage > .Image > img {
  height: 100%;
}
.GenericPromoHeader__contentWrapper__leftImage {
  position: absolute;
  left: 0;
  height: 100%;
}
@media (max-width: 767px) {
  .GenericPromoHeader__contentWrapper__leftImage {
    display: none;
  }
}
.GenericPromoHeader__contentWrapper__leftImage > .Image {
  height: 100%;
}
.GenericPromoHeader__contentWrapper__leftImage > .Image > img {
  height: 100%;
}
.GenericPromoHeader__contentWrapper__rightImage {
  position: absolute;
  right: 6rem;
  top: 0;
  margin-right: 1rem;
  height: 100%;
}
@media (max-width: 767px) {
  .GenericPromoHeader__contentWrapper__rightImage {
    display: none;
  }
}
.GenericPromoHeader__contentWrapper__rightImage > .Image {
  height: 100%;
}
.GenericPromoHeader__contentWrapper__rightImage > .Image > img {
  height: 100%;
}
.GenericPromoHeader__contentWrapper__textWrapper {
  vertical-align: middle;
  padding: 1.2rem 0rem;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  text-align: center;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.GenericPromoHeader__contentWrapper__textWrapper__text {
  display: initial;
}
@media (max-width: 991px) {
  .GenericPromoHeader__contentWrapper__textWrapper__text {
    display: none;
  }
}
.GenericPromoHeader__contentWrapper__textWrapper__textMobile {
  display: none;
}
@media (max-width: 991px) {
  .GenericPromoHeader__contentWrapper__textWrapper__textMobile {
    display: initial;
  }
}
@media (max-width: 991px) {
  .GenericPromoHeader__contentWrapper__textWrapper__countdown {
    display: inline-block;
  }
}
.GenericPromoHeader__closeButton {
  vertical-align: middle;
  position: absolute;
  right: 3.5rem;
  top: 0;
  margin-top: 1.2rem;
  z-index: 2;
  display: none;
}
.GenericPromoHeader__contentWrapper__textWrapper--flipped {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.GenericPromoHeader__contentWrapper__textWrapper-front,
.GenericPromoHeader__contentWrapper__textWrapper-back {
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  transform: rotateX(0deg);
  left: 0;
  width: 100%;
  height: 100%;
}
.GenericPromoHeader__contentWrapper__textWrapper-back {
  top: -0.6rem;
}
.GenericPromoHeader__contentWrapper__textWrapper-front {
  top: 1.5rem;
}
.GenericPromoHeader__contentWrapper__textWrapper-back {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
