/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PromoCountdown {
  margin: 0 auto;
  max-width: 70rem;
  min-width: 56rem;
  width: 100%;
  padding: 2rem;
  margin-bottom: 0;
  font-weight: 900;
}
.PromoCountdown .values div {
  width: 25%;
  display: inline-block;
  text-align: center;
}
.PromoCountdown .legend {
  font-size: 1.6rem;
}
.PromoCountdown .legend div {
  width: 25%;
  display: inline-block;
  text-align: center;
}
@media (max-width: 991px) {
  .PromoCountdown {
    min-width: 27rem;
  }
}
.PromoCountdown--compact {
  margin: 0 auto;
  max-width: 70rem;
  min-width: 56rem;
  width: 100%;
  padding: 0 1rem;
}
.PromoCountdown--compact .values div {
  width: 25%;
  display: inline-block;
  text-align: center;
}
.PromoCountdown--compact .legend {
  font-size: 1.2rem;
}
.PromoCountdown--compact .legend div {
  width: 25%;
  display: inline-block;
  text-align: center;
}
