/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.button {
  display: inline-block;
  border-radius: 5rem;
  color: #fff;
  padding: 2.4rem 4.4rem;
  border: none;
  margin: 0.5rem;
  transition: all 0.15s ease 0s;
  transition: all 0.15s;
  font-family: 'Zona Pro', 'Verdana';
  font-size: 1.8rem;
  font-weight: bold;
}
.button:focus,
.button:hover {
  outline: none;
  color: #fff;
}
.button:focus {
  box-shadow: 0 0 0 0.1em #fff;
}
.button > .status > * {
  display: inline-block;
}
.button > .status > .sprite {
  margin-right: 1rem;
}
.button.large {
  font-size: 1.5rem;
  padding: 0.8rem 2rem;
}
.button.medium {
  font-size: 1.6rem;
  padding: 1.4rem 3.2rem;
}
.button.small {
  font-size: 1.4rem;
  padding: 1.4rem 3.2rem;
}
.button.blue {
  background: #4a82ff;
}
.button.blue:hover {
  background: #0046e3;
  color: #fff;
}
.button.red {
  background: #ff486b;
}
.button.red:hover {
  background: #e1002b;
  color: #fff;
}
.button.grey {
  background: #e4d7b7;
}
.button.grey:hover {
  background: #ddcca5;
}
.button.green {
  background: #86c323;
}
.button.green:hover {
  background: #77ae1f;
}
.button.yellow {
  background: #ffdc4a;
  color: #534468;
}
.button.yellow:hover {
  background: #e3b700;
}
