/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#container > .LegacyModal.message > .content > .message {
  text-align: center;
  color: #2d2d2d;
  font-size: 17px;
}
#container > .LegacyModal.message > .content > .header {
  font-weight: 600;
  height: 180px;
  overflow: hidden;
}
#container > .LegacyModal.message > .content > .header > .image {
  width: 240px;
  float: left;
}
@media (max-width: 767px) {
  #container > .LegacyModal.message > .content > .header > .image {
    display: none;
  }
}
@media (max-width: 767px) {
  #container > .LegacyModal.message > .content > .header {
    height: 50px;
  }
}
#container > .LegacyModal.message > .content > .header > .subtitle {
  margin-left: 250px;
  text-align: left;
}
@media (max-width: 767px) {
  #container > .LegacyModal.message > .content > .header > .subtitle {
    display: none;
  }
}
#container > .LegacyModal.message > .content > .header > .title {
  margin-left: 250px;
  text-align: left;
  padding-top: 65px;
  background: none;
}
@media (max-width: 767px) {
  #container > .LegacyModal.message > .content > .header > .title {
    margin-left: 0;
    text-align: center;
    padding: 0;
    font-size: 30px;
  }
}
#container > .LegacyModal.message > .content > .header > .title.green {
  background: #52b958;
}
#container > .LegacyModal.message > .content .title {
  text-shadow: none;
  font-size: 30px;
  padding: 30px;
  text-transform: capitalize;
  margin-left: 0;
  color: #fff;
  background: #633c6f;
}
#container > .LegacyModal.message > .content .subtitle {
  padding: 20px;
}
#container > .LegacyModal.message > .content .validatedInput > textarea {
  border: 0;
  background: #fff;
}
