/* Global Mixins and variables */

@import '~bootstrap/less/variables.less';

.monospace() {
	font-family: 'Inconsolata', 'Courier';
}

.zonaPro() {
	font-family: 'Zona Pro', 'Verdana';
}

.cabin() {
	font-family: 'Cabin', 'Verdana';
}

.semiBold() {
	font-weight: 600;
}

.ckTitle() {
	font-size: 3em;
	font-weight: 600;
	color: @purple2;
	.zonaPro();
	text-align: center;

	@media (max-width: @screen-sm-max) {
		font-size: 2.2em;
		margin-left: 1rem;
	}
}

.ckSubtitle() {
	font-size: 2em;
	font-weight: 600;

	@media (max-width: @screen-sm-max) {
		font-size: 1.6rem;
	}

	margin-bottom: 4rem;
	color: @purple2;
}

.ckSectionTitle() {
	.ckTitle();
	margin-top: 60px;
	margin-bottom: 20px;

	@media (max-width: @screen-sm-max) {
		margin-left: 10px;
	}
}

.ckSectionSubtitle() {
	.ckSubtitle();
	font-size: 24px;
	text-align: center;
}

.ckGreenSubtitle() {
	color: @ckGreen1;
	font-size: 32px;
	padding-bottom: 10px;
	margin-bottom: 0;
}

@import './CK-MIXINS.less';
@import './deprecatedPalette.less';
@import './Palette.less';
@import './branding.less';

@menuHeight: 9rem;
@menuCompactHeight: 6rem;
@menuPromoHeight: 9rem;
@menuLinksMobileStartingWidth: 1035px;
