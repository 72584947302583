@import '~bootstrap/less/variables.less';
@import '~bootstrap/less/mixins.less';
@import './deprecatedPalette.less';
@import './Palette.less';

html {
	height: 100%;
	zoom: 1;
	font-size: 10px !important;

	@media (max-width: @screen-sm-max) {
		font-size: 7px !important;
	}

	@media (max-width: @screen-md-max) {
		font-size: 9px !important;
	}
}

body {
	margin: 0;
	padding: 0;
	cursor: default;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: antialiased;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-callout: none;
	user-drag: none;

	&.pageLoading * {
		cursor: wait !important;
	}

	overflow: auto !important;
	font-family: 'Zona Pro', 'Verdana', sans-serif !important;

	.intercom-lightweight-app-launcher {
		@media (max-width: @screen-sm-max) {
			margin-bottom: 8rem;
		}
	}
}

a {
	text-decoration: none;
	color: @blue5;
	transition: all 0.15s;
	display: inline-block;
}

a.asyncLink.pending {
	cursor: wait;
	opacity: 0.2;
}

a:hover {
	color: @lightBlue2;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: normal;
}

.sprite {
	vertical-align: middle;
	background-repeat: no-repeat;
}

.ui-widget.ui-front {
	z-index: 5000;
	font-size: 1.4rem;
}

.scroll_list {
	color: #fff;
	background: #444;
	max-height: 350px;

	&.light {
		background: #fff;

		> ul {
			> li {
				&:hover {
					background-color: @ckLilac1;
				}

				&:nth-child(odd) {
					background-color: #f0f0f0;

					&:hover {
						background-color: @ckLilac1;
					}
				}
			}
		}
	}

	> ul {
		margin: 0;
		padding: 0;
		list-style: none;
		max-height: none;

		> li {
			font-weight: 600;

			.chunk {
				font-weight: normal;
			}

			padding: 2px;
			transition: all 0.15s;
			cursor: pointer;
			border-radius: 1rem;

			&:hover {
				background: #2e233d;
			}

			&.selected {
				background: #0d6f9f;
				cursor: default;
				color: #fff;
			}

			&.ignore:hover {
				background: none;
				cursor: default;
			}

			&.heading {
				padding: 12px 8px 4px;
				color: #aaa;

				&:hover {
					background-color: #444;
				}
			}
		}
	}

	&.no_background {
		background: none;

		li {
			&:hover {
				background: none;
			}
		}
	}
}

.scrollable {
	overflow: hidden;
	position: relative;

	> .iScrollVerticalScrollbar {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 10px;
		padding: 2px 2px 4px;
		/* background: rgba(195, 234, 252, 0.6); */
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;

		> .iScrollIndicator {
			background: rgba(255, 255, 255, 0.3);
			border-radius: 6px;
			width: 10px;
			margin-top: -2px;
		}
	}
}

.disabledOverlay {
	filter: grayscale(100%) opacity(0.8);
	background-color: @ckGrey5;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1000000;
}

.buttonWrapper {
	> .button {
		vertical-align: bottom;
	}
}

input {
	/* iOS devices render with a border radius, not great for search bar styling */
	border-radius: 0;
}

div.staffInfo {
	a {
		text-shadow: none;
		color: white;
		cursor: pointer;
	}

	a:hover {
		text-decoration: underline;
	}

	z-index: 1000001;
	position: fixed;
	left: 0;
	top: 0;
	padding: 0.5rem;
	color: black;
	background: fade(@red, 70%);
	text-shadow: 0.1rem 0.1rem 0.1rem white;
	font-size: 1.5rem;
	border-radius: 0 0 1rem 0;
}

.intercomBlanker {
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	width: 6rem;
	height: 6rem;
	z-index: 2147483646;
	background-color: #4a81ff;
	border-radius: 50%;
	cursor: pointer;
}

.intercomPlaceholder {
	position: fixed;
	bottom: 9rem;
	right: 2rem;
	width: 35rem;
	max-width: ~'calc(100vw - 2rem)';
	height: 6.5rem;
	border-radius: 1rem;
	background-color: white;
	padding: 1rem;
	font-size: 1.5rem;
	box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.16);
	user-select: text;
}

.website_frame {
	.Modal {
		max-width: 75rem;
	}

	.Modal__content {
		padding: 0;
	}

	.Modal--grey {
		background-color: #000;
		border: 0.1rem solid #fff;
	}

	.VideoModal {
		height: 43rem;
	}
}
