@import '~bootstrap/less/variables.less';
@import './deprecatedPalette.less';
@import './Palette.less';

#container > .LegacyModal {
	z-index: 5000;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	font-size: 18px;

	&.message {
		.content {
			margin-left: -300px;
			width: 600px;
			@media (max-width: @screen-xs-max) {
				margin-left: 0;
				width: 90%;
			}

			.message {
				margin: 50px 40px;
				@media (max-width: @screen-xs-max) {
					margin: 20px;
					font-size: 16px;
				}
			}
		}
	}

	.button {
		margin-bottom: 30px;
	}

	> .content {
		z-index: 166 !important;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -380px;
		margin-top: -290px;
		width: 760px;
		z-index: 5000;
		transition: all 0.15s;
		border-radius: 6px;
		border: 0;
		background-color: @ckGrey3;
		border-radius: 8px;
		overflow: hidden;
		text-align: center;
		-webkit-box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.75);
		box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.75);
		@media (max-width: @screen-xs-max) {
			top: 5%;
			left: 5%;
			margin-top: 0;
			margin-left: 0;
			width: 90%;
		}

		.inner {
			margin: 0 20px;
		}
	}

	> .background {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		z-index: 165;
	}

	.blueBox {
		padding: 40px;
		border-radius: 5px;
		background: @ckGrey3;
		color: @ckGrey1;
	}

	&.error {
		> .content {
			> .header {
				background: @red;
			}

			> .title {
				background: @red;
			}
		}
	}

	&.blue {
		> .content {
			> .header {
				background: @blue;
			}

			> .title {
				background: @blue;
			}
		}
	}

	.choiceList {
		border: 5px solid @ckGrey5;
		border-radius: 5px;
		background: @ckLilac1;
		max-height: 300px;
		min-height: 150px;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			> li {
				cursor: pointer;
				padding: 20px;
				text-align: left;
				font-size: 18px;

				&:hover {
					background: darken(@ckLilac2, 5%);
				}

				&:nth-child(odd) {
					background: @ckLilac2;

					&:hover {
						background: darken(@ckLilac2, 5%);
					}
				}
			}
		}
	}
}
