/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.FooterPromo {
  height: 40rem;
}
.FooterPromo .intro,
.FooterPromo .title {
  text-align: center;
}
.FooterPromo.blackfriday {
  background-color: #221f25;
  background-size: cover;
}
.FooterPromo.homeschooling {
  background-size: cover;
  background-color: #4a82ff;
}
@media (max-width: 767px) {
  .FooterPromo.homeschooling {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
.FooterPromo.holidaysale {
  background-color: #ff486b;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 991px) {
  .FooterPromo.holidaysale {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
}
.FooterPromo.easter {
  background-color: #9cd541;
  background-size: cover;
}
@media (max-width: 767px) {
  .FooterPromo.easter {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
@media (max-width: 991px) {
  .FooterPromo.easter .title,
  .FooterPromo.easter .intro {
    color: #445d1b;
  }
}
.FooterPromo .title {
  font-size: 7rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}
@media (max-width: 991px) {
  .FooterPromo .title {
    font-size: 4rem;
  }
}
.FooterPromo .intro {
  font-size: 2.4rem;
  font-weight: 600;
  padding: 5rem 2rem 2rem;
  color: #fff;
}
.FooterPromo .buttonWrapper {
  text-align: center;
  width: 100%;
}
.FooterPromo.summer {
  background-size: cover !important;
  background: #b6e7ff;
}
@media (max-width: 767px) {
  .FooterPromo.summer {
    background-size: 100% !important;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
.FooterPromo.summer .title {
  color: #007dba;
}
.FooterPromo.summer > .intro {
  color: #007dba;
}
